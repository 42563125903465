

// Doc ready
$(document).ready(function () {
    novicell.custom.departmentsDropdown.init();
    novicell.lazyload.image();
    novicell.custom.caseSlider.init($("#caseslider"));
    novicell.custom.rollUp.init();
    novicell.custom.accordion.init();
    novicell.custom.accordion.anchorLink();
    novicell.custom.categoryPageText();
    novicell.custom.mobileSearch();
    novicell.custom.categoryPageText();
    novicell.custom.postNumber();
    novicell.custom.initDatePicker();
    novicell.custom.stripeMe();
    novicell.custom.youtube();
    novicell.custom.mapfilter();
    novicell.custom.popup();
    novicell.custom.gallery();
    novicell.custom.overlayExistingElement.init();
    //novicell.custom.hidecontainer($("#campaign-btb"));
    //novicell.custom.hidecontainer($("#newssliderTop"));

    //Kulturplantensdag - Nordicseed
    $('#Angivhvormangepersonerduoenskerattilmelde').on('change input keyup paste', function () {
        var maxParticipants = 10;
        var amount = parseInt($(this).val());
        if (!isNaN(amount)) {
            for (var i = 1; i < maxParticipants; i++) {
                if (i <= amount) {
                    $('#tabId_' + i).show();
                    $('#Deltager' + i + 'Navn').attr('required', 'true');
                    $('#Deltager' + i + 'Telefonnr').attr('required', 'true');
                    $('#Deltager' + i + 'Email').attr('required', 'true');
                } else {
                    $('#tabId_' + i).hide().find('input').removeAttr('required');

                }
                
            }
        }
    });

    var fixedelementHeight = $("#parent-abs").outerHeight();
    var middlecontentHeight = $("#right-col-fixed").height();
    if (middlecontentHeight < fixedelementHeight) {
        $("#right-col-fixed").css("min-height", fixedelementHeight);
    }

    if ($("div#featurettes").height() <= 52) {
        $("div#featurettes").css('display', 'none');
    }

    if ($('#campaigns').length > 0) {
        if ($('#campaigns').is(':empty')) {
            $('#campaign-btb').css("display", "none");
        }
    }

    $('div.tab-content-right').each(function () {
        if ($(this).is(':empty')) {
            $(this).css('display', 'none');
            $(this).prev().removeClass('col-md-8 col-lg-8');
        };
    });
    $('.dropdown-toggle').dropdown();
    $('input').placeholder();
    $('form#ordersubmit').removeClass("form-horizontal");
    $('#add-to-basket').click(function () {
        var quantity = parseInt($('input#Quantity').val());
        var stock = parseInt($('#stock-container input#stockstatus').val());
        if (quantity > stock) {
            alert("antal produkter oversteg lagerbeholdning");
        }
        else {
            novicell.custom.updatebasket();
        }
    });

    // enable menu item if no dropdown
    $('.navbar-nav li.dropdown a[target="_blank"]').addClass('disable');
    $('.navbar-nav li.dropdown a').click(function () {
        var sizeDropdown = $(this).parent('li').children('ul').size();

        if (sizeDropdown == "0") {
            if (!$(this).hasClass("disable"))
            {
                window.location = $(this).attr('href');
            }
        }
    });

    // Responsive table
    if ($(window).width() <= 1024) {
        novicell.custom.responsivetable();
    }

    //For Swedish Agro employee list
    if ($(".employee-info").length > 0) {
        var counter = 0;
        var previousHeight = 0;
        $(".employee-info").each(function () {
            if (counter == 0) {
                previousHeight = $(this).height() + 20;
                $(this).css("height", previousHeight + "px");
                counter += 1;
            }
            else {
                if (previousHeight > $(this).height()) {
                    $(this).css("height", previousHeight + "px");
                }

                counter = 0;
            }
        });
    }

    if ($('#forum-info-sticky').length > 0) {
        $('#forum-info-sticky').affix({
            offset: {
                top: $('#forum-post-content').position().top + 25,
                bottom: function () {

                    var bodyHeight = $('body').outerHeight(true);
                    var contentHeight = $('#forum-post-content').outerHeight(true);
                    var contentTop = $('#forum-post-content').position().top;
                    var bottomHeight = bodyHeight - (contentTop + contentHeight);
                    return (this.bottom = bottomHeight)
                }
            }
        });
    }

    if ($("#newMessageForm").length > 0) {
        $("#newMessageForm").on("submit", function (event) {

            if ($(".g-recaptcha").length > 0)
            {
                var captcha = grecaptcha.getResponse();

                if(captcha == "")
                {
                    event.preventDefault();
                }
            }
        });
    }

    if ($("#sliderOwl").length) {
        $("#sliderOwl").owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            autoplay: true,
            dots: false,
            navSpeed: 1500,
            dotsSpeed: 1500,
            autoplaySpeed: 1500,
            autoplayHoverPause: true,
            responsive: {
                992: {
                    dots: true
                }
            }
        });
    }

    if ($("#newssliderOwl").length) {
        $("#newssliderOwl").owlCarousel({
            loop: false,
            nav: true,
            dots: false,
            autoplay: false,
            slideBy: 4,
            responsive: {
                0: {
                    items: 1,
    },
                768: {
                    items: 2
                },
                1200: {
                    items: 4
                }
            },
            navSpeed: 800,
            autoplaySpeed: 1500
        });
    }

    //if ($("#caseslider").length) {
    //    $("#caseslider").owlCarousel({
    //        loop: true,
    //        nav: true,
    //        dots: false,
    //        autoplay: true,
    //        slideBy: 2,
    //        responsive: {
    //            0: {
    //                items: 1
    //            },
    //            768: {
    //                items: 2
    //            }
    //        },
    //        navSpeed: 1000,
    //        autoplaySpeed: 1500
    //    });
    //}
});

// window load
$(window).load(function () {
    // * * outcommented and moved to docready. ** / /
    novicell.custom.slider.init($("#slider"));
    novicell.custom.newsSlider.init($("#newsslider"));
    novicell.custom.caseSlider.init($("#caseslider"));

    novicell.custom.stickyNav.init("#affix-sidebar", ".primary-page-content", ".right-col-fixed", "#right-col-fixed");
    novicell.custom.cookieinfo.init();
}); // window load


// window hashchange
window.onhashchange = function () {
    novicell.custom.accordion.anchorLink();
}; // window hashchange

// window resize
$(window).resize(function () {
    if ($(window).width() <= 1024) {
        novicell.custom.responsivetable();
    }
    novicell.custom.youtube();
});
// window resize

novicell.custom = new function () {

    this.overlayExistingElement = new function (selectors) {
        this.init = function () {
            $('body').on('click', '.overlay-element', function (e) {
                e.preventDefault();
                // get data
                var overlayClass = $(this).attr('data-class');
                var content = $('#' + $(this).attr('data-src')).html();
                novicell.overlay.set({ 'content': content, 'class': overlayClass });
                if ($(".ncOverlay-inner-scroll > div").hasClass('terms-and-conditions')) {
                    $(".ncOverlay-inner-scroll, .ncOverlay-inner").css('padding', '0px');
                }
            });
        };
    };

    this.departmentsDropdown = new function () {
        this.init = function () {
            if ($(".departments_dropdown").length) {
                $(".departments_dropdown").each(function () {
                    $(this).click(function () {
                        $(this).toggleClass("departments_focus");
                        $(this).find('div.region_list').slideToggle('fast');
                    })
                });

                $(document).mouseup(function (e) {
                    var container = $(".departments_dropdown");

                    if (!container.is(e.target) // if the target of the click isn't the container...
						&& container.has(e.target).length === 0) // ... nor a descendant of the container
                    {
                        $(".region_list").hide();
                        $(".departments_dropdown").removeClass("departments_focus");
                    }
                });
            };
        };
    };

    this.stickyNav = new function () {
        this.init = function (selector, parent, rightselector, rightparent) {
            parent = $(parent);
            selector = $(selector);
            rightselector = $(rightselector);
            rightparent = $(rightparent);
            if ($(selector).length) {
                // Cache selectors outside callback for performance.
                var rightCol = $(selector).parent().next();
                var rightColHeight = $(rightCol).outerHeight();
                var affixSidebarHeight = $(selector).outerHeight();
                if (rightColHeight > affixSidebarHeight) {

                    var $window = $(window),
                        elTop = selector.offset().top;

                    $window.scroll(function () {
                        var parentBottom = parent.offset().top + parent.outerHeight();
                        var elBottom = $window.scrollTop() + selector.outerHeight(true);

                        selector.toggleClass('affix', $window.scrollTop() > elTop && (elBottom <= parentBottom));
                        selector.toggleClass('affix-bottom', ($window.scrollTop() > elTop) && (elBottom > parentBottom));
                        $('#parent-abs').toggleClass('bottom-align', ($window.scrollTop() > elTop) && (elBottom > parentBottom));
                    }).trigger('scroll');
                }

            }


            if ($(rightselector).length) {
                // Cache selectors outside callback for performance.
                var $window1 = $(window),
					elTop1 = rightselector.offset().top;

                $window1.scroll(function () {
                    var parentBottom1 = rightparent.offset().top + rightparent.outerHeight();
                    var elBottom1 = $window1.scrollTop() + rightselector.outerHeight(true);

                    rightselector.toggleClass('affix', $window1.scrollTop() > elTop1 && (elBottom1 <= parentBottom1));
                    rightselector.toggleClass('affix-bottom', ($window1.scrollTop() > elTop1) && (elBottom1 > parentBottom1));
                }).trigger('scroll');

            }
        };
    };
    /*could be removed.. perhaps */
    this.stickyMenu = new function () {
        this.init = function () {
            //Init sticky sidenav
            var affixSidebar = $('#affix-sidebar');
            if ($('#affix-sidebar').length > 0) {
                var rightCol = $('#affix-sidebar').parent().next();
                var rightColHeight = $(rightCol).outerHeight();
                var affixSidebarHeight = $(affixSidebar).outerHeight();
                console.log(rightColHeight)
                console.log(affixSidebarHeight)
                if (rightColHeight > affixSidebarHeight){
                    $('#affix-sidebar').affix({
                        offset: {
                            top: $('.primary-page-content').position().top + 25,
                            bottom: function () {

                                var bodyHeight = $('body').outerHeight(true);
                                var contentHeight = $('.primary-page-content').outerHeight(true);
                                var contentTop = $('.primary-page-content').position().top;
                                var bottomHeight = bodyHeight - (contentTop + contentHeight);
                                return (this.bottom = bottomHeight)
                            }
                        }
                    });
                }
            }

            if ($('#downloadContent.right-col-fixed, #rightSide.right-col-fixed').length > 0) {
                console.log($('#downloadContent.right-col-fixed, #rightSide.right-col-fixed').length)

                $('#downloadContent.right-col-fixed, #rightSide.right-col-fixed').affix({
                    offset: {
                        top: $('#right-col-fixed').position().top + 25,
                        bottom: function () {

                            var bodyHeight1 = $('body').outerHeight(true);
                            var contentHeight1 = $('#right-col-fixed').outerHeight(true);
                            var contentTop1 = $('#right-col-fixed').position().top;
                            var bottomHeight1 = bodyHeight1 - (contentTop1 + contentHeight1);
                            return (this.bottom = bottomHeight1)
                        }
                    }
                });
            }
        };
    };

    this.rollUp = new function () {
        this.init = function () {
            //Open roll-up
            $('.roll-up-toggle').click(function () {
                $(this).closest('.roll-up').addClass('active');
                $('body').prepend($('<div/>').attr('id', 'overlay-bg'));

            });

            //Close roll-up
            $('body').on('click', '#overlay-bg, .roll-up .close', function () {
                $('.roll-up.active').removeClass('active');
                $('#overlay-bg').fadeOut().remove();
            });
        };
    };

    this.newsSlider = new function () {
        this.init = function (slider) {
            slider.carouFredSel({
                items:4,
                //items: {
                //    visible: {
                //        min: 1,
                //        max: 4
                //    },
                //    minimum: 2
                //},
                responsive: true,
                swipe: true,
                width: "100%",
                align: "center",
                prev: ".newsslider .slider-prev",
                next: ".newsslider .slider-next",
                auto: true,
                circular: true,
                infinite: true,
                pagination: false,
                scroll: {
                    pauseOnHover: true,
                    duration: 1500,
                }
            });
        };
    };

    this.newnewsSlider = new function () {
        this.init = function (slider) {
            slider.carouFredSel({
                items: {
                    visible: {
                        min: 2,
                        max: 3
                    },
                    minimum: 2
                },
                responsive: true,
                swipe: true,
                width: "100%",
                align: "center",
                prev: ".News-Slider .slider-prev",
                next: ".News-Slider .slider-next",
                auto: true,
                circular: true,
                infinite: true,
                pagination: false,
                scroll: {
                    pauseOnHover: true,
                    duration: 1500,
                }
            });
        };
    };

    this.caseSlider = new function () {
        this.init = function (slider) {
            slider.carouFredSel({
                items: {
                    visible: {
                        min: 1,
                        max: 2
                    },
                    minimum: 2
                },
                responsive: true,
                swipe: true,
                width: "100%",
                align: "center",
                prev: ".caseslider .slider-prev",
                next: ".caseslider .slider-next",
                auto: false,
                circular: true,
                infinite: true,
                pagination: false
            });
        };
    };

    this.slider = new function () {
        this.init = function (slider) {
            slider.carouFredSel({
                responsive: true,
                swipe: true,
                width: "100%",
                align: "center",
                prev: ".slider-prev",
                next: ".slider-next",
                auto: true,
                circular: true,
                infinite: true,
                pagination: {
                    container: "#slider-bullets",
                    anchorBuilder: true
                },
                scroll: {
                    pauseOnHover: true,
                    duration: 1500,
                }
            });
        };
    };

    this.cookieinfo = new function () {
        $cookiebox = $('#cookieInfo');
        this.init = function (args) {
            this.elm = $('#cookieInfo');
            this.options = args;

            if ($.cookie("cookieAccept") != undefined && $.cookie("cookieAccept") == "displayed") {
                $.cookie("cookieAccept", "accepted", { expires: 365, path: '/' });
            }

            if ($.cookie("cookieAccept") == undefined /*&& args.autohide != true*/) {
                $.cookie("cookieAccept", "displayed", { path: '/' });
                render($cookiebox, { 'mode': 'show' });
            } else {
                render($cookiebox, { 'mode': 'hide' });
            }

            $('body').on('click', 'a#cookie-info-toggle', function (e) {
                e.preventDefault();
                if ($cookiebox.attr('data-mode') == 'show') {
                    render($cookiebox, { 'mode': 'hide' });
                }
                else {
                    render($cookiebox, { 'mode': 'show' });
                }
            });
        }
        this.toggle = function () {

        }
        var render = function (container, args) {
            container.html('');
            var btn = $('<a>').attr({ 'id': 'cookie-info-toggle', 'href': '#cookieInfo' + args.mode, 'class': 'btn btn-success', 'onclick': 'parent.window.scrollTo(0,0);' }).text($cookiebox.data('btn-text-' + args.mode));
            var markup = '';
            if (args.mode == 'show') {
                var cookieInfoText = $('<div>').attr({ 'class': 'col-xs-12 col-sm-9 cookie-info-text' });
                $.ajax({
                    url: container.attr('data-cookie-info-path'),
                    dataType: 'html',
                    success: function (data) {
                        cookieInfoText.html(data);
                        container.addClass('cookie-info-' + args.mode);
                    }
                });
                markup = $('<div>').addClass('container').append($('<div>').addClass('row').append(cookieInfoText, $('<div>').addClass('col-xs-12 col-sm-3').append(btn)));
                $('#cookie-info-toggle').remove();
                container.html(markup).prependTo('body');
                container.show();
            }
            else {
                if ($('#cookieInfo').length) {
                    markup = btn;
                    $('#footer-text').append($(markup).attr('class', ''));
                }
                container.hide()
            }
            container.attr('data-mode', args.mode);
        }
    };

    this.accordion = new function () {
        this.init = function () {
            $('.accordion-item h2').on('click', function (e) {
                e.preventDefault();
                var self = $(this).parent("li"),
                    selfContent = $(this).next('.accordion-content'),
                    accordionContents = $('.accordion-item').find('.accordion-content');
                if (self.hasClass("active")) {
                    $('.accordion-item').removeClass("active");
                    accordionContents.slideUp(300);
                }
                else {
                    $('.accordion-item').removeClass("active");
                    self.addClass("active");
                    accordionContents.slideUp(300);
                    selfContent.slideDown(300);
                }
            });
        }

        this.anchorLink = function () {
            var hash = window.location.hash;

            if (hash != '' && $(hash).length) {
                var accordionParent = $(hash).parents('.accordion-item');

                if (accordionParent.length) {
                    //console.log('haz hash');
                    var activeItem = $('.accordion-item.active');

                    activeItem.removeClass("active");
                    activeItem.find('.accordion-content').hide();

                    accordionParent.addClass("active");
                    accordionParent.find('.accordion-content').show();
                }

            }
        }
    };

    this.categoryPageText = function () {
        if ($(".category-page-text #rightSide").is(':empty')) {
            $(this).hide();
            $("#leftSide").removeClass('col-sm-8');
            $("#leftSide").addClass('col-sm-12');
        }
    };

    this.mobileSearch = function () {
        if (pageUrl != 'undefined') {
            var str = pageUrl;
            var subStr = str.split('=');
            var search = window.location.href;

            /*		Getting the current pageUrl - splits it into a substring (splits on '=' symbol)
					----->> checks whether the value on index lvl 2 is equal or greater than 1. If true, employee tab will be shown */
            if (subStr[2] >= 1) {
                $('.showstaff a').trigger('click');
            }


        }

        if ($('#show-border').is(':empty')) {
            $('#caseSlider').removeClass('top-border grey');
        }
        $(".container form").addClass("form-horizontal");
        $(".container form#ordersubmit").removeClass("form-horizontal");
        $(".container form input[type=text]").addClass("form-control");
        $(".container form textarea").addClass("form-control");
        $(".container form input[type=reset]").addClass("btn btn-default").css("margin-right", "15px");
        $(".container form input[type=submit]").addClass("btn btn-default");
        var next = $("#search-next").val();
        var Previous = $("#search-previous").val();

        $("#mobile-search-pagination a").each(function () {

            $("#mobile-search-pagination a").addClass("btn btn-large btn-success col-xs-5");
            if ($(this).is(":contains('»')")) {
                $(this).text(next + " »").css('float', 'right');
            } else {
                $(this).text("« " + Previous);
            }
        });
    };

    this.postNumber = function () {
        if ($("#downloadContent").is(':empty')) {
            $("#downloadContent").css('display', 'none');
            $("div#underImage").removeClass('col-lg-8');
            $("div#underImage").addClass('col-lg-12');
            $("div#mainContent").removeClass('col-lg-8');
            $("div#mainContent").addClass('col-lg-12');
        }
        $('#post-number').bind('keypress', function (e) {
            var code = e.keyCode || e.which;
            if (code == 13) {

                $("#post-search").trigger('click');
            }
        });
    };

    this.initDatePicker = function () {
        $('#date').pickadate({
            format: 'dd/mm/yyyy',
            formatSubmit: 'yyyymmdd',
            selectYears: true,
            selectMonths: true,
        });

        $('#xmlsearch').click(function () {

            var date = $('#date_hidden').val();
            $('#xmlsearchfield').val(date);
        });
    };

    this.stripeMe = function () {
        if ($('.stripeMe').length && !$('.stripeMe').html().trim()) {
            $('.th-hidden tr').css('display', 'none')
        }
    };

    this.youtube = function () { 
        var width = $(".youtube-video").width();
        var newheight = width * 9 / 16;

        $(".youtube-video").attr('height', newheight + 'px');
    };

    this.responsivetable = function () {
        $('table tr td').each(function () {
            cell = this.cellIndex;
            var text = $(this).closest('table').find('th:eq(' + cell + ')').text();
            $(this).attr('data-content', text);
        });
    };

    this.mapfilter = function () {
        $('#filters-checkbox input[type=checkbox]').click(function () {
            var filter = $(this).attr('class');
            $('#filters-checkbox input[type=checkbox]').on('change', function () {
                $('#filters-checkbox input[type=checkbox]').not(this).prop('checked', false);
            });
            $('#filters-button button.' + filter).trigger("click");
        });
    };

    this.updatebasket = function () {
        var productid = $("div#price-holder div#productid").text();
        var productqty = $("div.buy-product #Quantity").val();
        var variantID = $("div#price-holder div#varientid").text();
        $.ajax({
            url: '/shop/kurv/top-kurv-(ajax)?productid=' + productid + '&variantid=' + variantID + '&Quantity=' + productqty + '&cartcmd=add',
            cache: false,
            success: function (data) {
                $.ajax({
                    url: '/shop/kurv/top-kurv-(ajax)',
                    dataType: 'html',
                    success: function (htmlSource) {
                        $('#shopCart').html($(htmlSource));
                    }
                })
            }
        })
    };

    this.popup = function () {
        $("#InfoPopUp.poppedDown").click(function () {
            $(this).addClass('poppedUp');
            $(this).removeClass('poppedDown');
            console.log("PopUp");
        });

        $("#InfoPopUp .Popup .closePopup img").click(function (event) {
            event.stopPropagation();
            $("#InfoPopUp").addClass('poppedDown');
            $("#InfoPopUp").removeClass('poppedUp');
        });
    };

    this.gallery = function () {
        $(".fancybox-button").fancybox({
            prevEffect: 'none',
            nextEffect: 'none',
            closeBtn: true,
        });
    };

    this.hidecontainer = function (container) {
        if (container.length) {
            if (container.height() <= 66) {
                container.hide();
            }
        }
    };
};
